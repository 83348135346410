import Vue from 'vue'
import App from './App.vue'
import Vacuum from '@bonhomme/bonnie-vacuum';
import resizeManager from './resizeManager';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);


Vacuum.addClasses();

Vue.config.productionTip = false

Vue.prototype.$rm = resizeManager;
Vue.prototype.$rm.start();

new Vue({
  render: h => h(App),
}).$mount('#app')
