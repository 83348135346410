<template>
    <div class="sticky-hor js-scroll">
        <div class="box-sticky" >
            <div class="box-resize" id="box-resize"  >
                <div class="slide s1   ">
                    <div class="box-text-S1">
                        <h1>
                        <p class="t1">Maison de coiffure </p>
                        <p class="t2">dédiée au bien-être</p>
                        <p class="t3"> et au sur-mesure.</p>
                        </h1>
                    </div>
                </div>

                <div class="slide s2">
                    <div class="imgStudio"><img src="../assets/studio.png" alt=""></div>
                </div>
                <div class="slide s3  outer-container">
                    <div class="box-imgBackground">
                        <div class="imgBackground">
                            <img src="../assets/svg/Logo-Outline.svg" stroke-width="5" alt="">
                        </div>
                    </div>
                    <div class="box-text-S3 box-textImg">
                        <p>Formé dans de prestigieux  salons, Alexandre Pichoux a mis  à profit ses expériences  d’excellence pour créer le sien  et y proposer le meilleur de la  coiffure mixte en mariant votre  style à son univers.</p>
                    </div>
                    <div class="img-slide3-1 box-textImg1">
                        <img src="../assets/siege.png" alt="">
                    </div>
                    <div class="img-slide3-2 box-textImg2">
                        <img src="../assets/lavabo.png" alt="">
                        <div class="textS1 ">
                            <p>Véritable maison de coiffure, le  salon de coiffure Alexandre Pichoux, à  Paris 4e, vous reçoit désormais au cœur  du Marais pour un moment exclusif sous  le signe de la beauté et de la mise en  forme des cheveux.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import config from './../config';
import windowScrollPosition from '../window-scroll-position.js'

export default {

    mixins: [ windowScrollPosition('position') ],



    mounted: function() {


        //  recuperation des elements  box resize
        const $box = document.getElementById('box-resize');

        //  recuperation de l'image studio
        const $imgStudio = document.querySelector('.imgStudio');
        const imgStudioChild = $imgStudio.firstElementChild;


        // recuperation du block texte slide 1
        const $blockT2 = this.$el.querySelector('.t2');
console.log($blockT2)

        // recuperation du background du logo
        const imgbackLogo =  this.$el.querySelector('.imgBackground');

        // recuperation du box-texte-image de la slide 3
        const boxTImg =  this.$el.querySelector('.box-textImg');
        const boxTImg1=  this.$el.querySelector('.box-textImg1');
        const boxTImg2 =  this.$el.querySelector('.box-textImg2');

        let translate = {xMax: 0, yMax: 0};


        const resize = () =>
        {
            // box generale
            const boxWidth = $box.offsetWidth;
            const height = this.$el.offsetHeight;

            translate.xMax = boxWidth - this.$rm.width;
            translate.yMax = height - this.$rm.height

        };

        const enterframe = () =>
        {
            const progress = this.position[1] / translate.yMax;
            //  translate du block stickyhor
            let xrem = (progress * translate.xMax);
            xrem = xrem < 0 ? 0 : (xrem > translate.xMax  ? translate.xMax : xrem);
            //  si la largeur est superieur a 750px le translate x est annuler
            if (window.matchMedia("(min-width: 751px)").matches) {
                this.$el.style.transform = `translateX(${- xrem / config.fontSize}rem) `;




            }
            // this.$el.style.transform = `translateX(${- xrem / config.fontSize}rem) `;

            // section img studio parallax
            let progressImgS =(progress)*3
            progressImgS = progressImgS < 0 ? 0 : (progressImgS > 1 ? 1 : progressImgS);






            // calculer le progress pour le scale
            const progressScale = (progressImgS) * .2 + 0.8  ;

            $imgStudio.style.transform = `  scale(${progressScale })`;

            //  calculer le progress pour le scale inversé de l'img studio
            const scale = (1 - progressImgS) * .4 + 1;
            imgStudioChild.style.transform = ` scale(${scale })`;



            //  section block text slide 1
        //     const moveXT2 = ( progressImgS) * (-5) ;
        //    $blockT2.style.transform = ` translateX(${moveXT2}rem))`;

            // section img background slide 3
            let parallaxBgL = (1 - progress) * 6 ;
            parallaxBgL = parallaxBgL < 0 ? 0 : (parallaxBgL > 10 ? 10 : parallaxBgL);


              if (window.matchMedia("(min-width: 769px)").matches) {
                imgbackLogo.style.right = ` ${parallaxBgL*100 }px`;

            }

            //  section blox texte image text slide 3
              if (window.matchMedia("(min-width: 769px)").matches) {
                let parallaxoxTImg = (1 - progress) *( 0.5) ;
                let parallaxoyTImg =(1 - progress) *0.5;
                parallaxoxTImg = parallaxoxTImg < 0 ? 0 : (parallaxoxTImg > 10 ? 10 : parallaxoxTImg);
                parallaxoyTImg = parallaxoyTImg < -0.5 ? -0.5 : (parallaxoyTImg > 0 ? 0 : parallaxoyTImg);
                boxTImg.style.transform = ` translateX(${parallaxoxTImg *100 }rem) translateY(${parallaxoyTImg *100 }rem)`;
                boxTImg1.style.transform = ` translateX(${parallaxoxTImg *100 }rem) translateY(${parallaxoyTImg *100 }rem)`;
                boxTImg2.style.transform = ` translateX(${parallaxoxTImg *100 }rem) translateY(${parallaxoyTImg *100 }rem)`;
            }

            requestAnimationFrame(enterframe);
        };

        requestAnimationFrame(enterframe);

        this.$rm.on('r', resize);
        resize();

    }
}



</script>

<style lang="scss" scoped>

.box-sticky{
    width: 100vw;
    height: 250vw;

    @media (max-width: 768px) {
        width: 100vw;
        height: 270vh;


    }

}
.box-resize{
    // border: rgb(255, 0, 0) 2px solid;
    width: 260vw;
    height: 100vh;
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: row;
    transform: translateX(100vw);
    background-color: white;
    animation: moveStickyX 3000ms  10s forwards ease-out;
    // version < 768px
    @media (max-width: 768px) {
        width: 100vw;
        height: 270vh;
        flex-direction: column;
        animation: moveStickyY 3000ms  10s forwards ease-out;
        transform: translateX(0vw) translateY(100vh);
    }
     // version < 425px
    @media (max-width: 425px) {



    }
    & .slide{
        width: 100vw;
        height: 100vh;


    }
    & .s1{

        display:flex;
        width: 60vw;
        align-items: center;
        @media (max-width: 768px) {
            width: 100vw;
            height: 50vh;
            justify-content: center;


        }

    }
    & .box-text-S1{

        margin-left: 5%;
        width:56vw;
        height: 41.6vh;
        font-size:3.1vw;
        display: flex;
        align-items: start;
        flex-direction: column;
        @media (max-width: 768px) {
            width:100vw;
            height:50vh;
            margin-left:0;
            font-size:4.5vw;
            align-items: center;
            justify-content: flex-end;
        }
        @media (max-width: 425px) {
            width:100vw;
            height:41.6vh;
            margin-left:0;
            font-size:4.5vw;
            align-items: center;
            justify-content: flex-end;
        }

        .t1 {
            transform: translateX(-5rem);
            animation: moveStickyT1X 2000ms  11s forwards ;
            @media (max-width: 768px) {
            animation: none;
            transform: translateX(0rem);
            }
        }
        .t3 {
            transform: translateX(5rem);
            animation: moveStickyT3X 2000ms  11s forwards ;
            @media (max-width: 768px) {
                animation: none;
                transform: translateX(0rem);
            }
        }
        & p{

            font-family: 'Marcellus';
            font-weight: 400;
        }

    }
    & .s2{

        display: flex;
        align-items: center;
        z-index: 2;
        & .imgStudio{
            width: 100%;
            height:100vh;
            overflow: hidden;
            & img{
                width:100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    & .s3{
        position: relative;
        display: flex;
        padding-top:24.07vh;

        @media (max-width: 768px) {
            // width:100%;
            height:200vh;
            flex-direction: column;
             padding-top:9.25925vh;

        }
        @media (max-width: 425px) {

            flex-direction: column;
            justify-content:center;

        }
        .box-textImg{
            transform: translateX(0rem);
        }
        .box-imgBackground{
            width: 100%;
            position: absolute;
            top: 0;
            overflow: hidden;
        }
        & .imgBackground{
            position: relative;
            width: 100vw;
            height: auto;
            top: -21%;
            right: 0;
            left:-5%;
            @media (max-width: 768px) {
            transform: rotateZ(270deg);
            object-fit: cover;
            width: 200%;
            top: 184px;
            left: -136px;
            }
        }

        & .box-text-S3{
        text-indent:70px;
        width: 27.44vw;
        height:29.16vh;
        font-size: 1.7vw;
        padding: 0;
        font-family:Macellus ;
        font-weight: 400;
        @media (max-width: 768px) {
            font-size: 3.4vw;
            width:  71.2vw;
            height:26.11vh;
            text-indent:35px;
        }
            & p{
                font-family: 'Marcellus';
                font-weight: 400;
            }
        }

        & .img-slide3-1{

        margin-left:3.125vw;
        width: 19.79vw;
        height:49.07vh ;
        overflow: hidden;
        z-index: 3;
            @media (max-width: 768px) {
                width: 40.59vw;
                height:auto ;
                overflow: hidden;
                margin-left:0;
                margin-bottom: 4rem;

            }
            & img{
            width: 100%;
            height: auto;
            }
        }
        & .img-slide3-2{

            z-index: 3;
            margin-left:3.125vw;
            width: 35.05vw;
            height:87vh ;
               @media (max-width: 768px) {
                width: 71.2vw;
                height: auto;
                margin-left:18%;

            }
             @media (max-width: 425px) {
                width: 71.2vw;
                height: 51.97vh;
                margin-left:18%;

            }

            & img{
                width: 100%;
                height: auto;
                @media (max-width: 768px) {
                    width: 100%;
                    height: 100%;
                    margin-left:0;
                }
            }
            & .textS1{
                width:35.05vw;
                height: 25vh;
                margin-top:3.70vh ;
                font-size:1.875vw;
                display: flex;
                 @media (max-width: 768px) {
                    font-size:3.7vw;
                    width: 71.2vw;
                    height:26.11vh;
                    text-indent:35px;
                 }
                & p{

                    font-family:Macellus ;
                    font-weight: 400;
                    text-indent:70px;
                    text-align: start;
                    @media (max-width: 768++px) {

                    text-indent:35px;
                 }
                    }
                }
            }

        }
}

@keyframes moveStickyX {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0vw);
    }
}
@keyframes moveStickyY {
    0% {
        transform: translateY(100vh);
    }
    100% {
        transform: translateY(0vh);
    }
}

@keyframes moveStickyT1X {
    0% {
        transform: translateX(-5rem);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes moveStickyT3X {
    0% {
        transform: translateX(5rem);
    }
    100% {
        transform: translateX(0rem);
    }
}



</style>
