import Vacuum from '@bonhomme/bonnie-vacuum';
import Emitter from 'tiny-emitter';
import config from './config';

class ResizeManager extends Emitter
{
    start()
    {
        // Init public variables
        this.width = 0;
        this.height = 0;
        this.vhMin = 0;
        this.vhMax = 0;
        this.bLandscape = null;
        this.isSmall = false;

        // Viewport
        this.vp = { width: 0, height: 0 };

        this._setup();
    }

    /* -------- Global resize -------- */

    /** @private */
    _setup()
    {
        // Init scope variables
        let _bDeviceBarVisible = false;
        let _vhDelta = 0;

        const $viewport = document.createElement('div');
        $viewport.style.width = '100vw';
        $viewport.style.height = '100vh';
        $viewport.style.position = 'absolute';
        $viewport.style.top = 0;
        $viewport.style.left = 0;
        $viewport.style.pointerEvents = 'none';

        // Resize handler
        const handler = () =>
        {
            // Get current window width and height
            const width = window.innerWidth;
            const height = window.innerHeight;

            // Update public vars
            this.width = width;
            this.height = height;
            this.isSmall = width < 1024;

            // Get viewport size
            document.body.appendChild($viewport);
            this.vp.width = $viewport.offsetWidth;
            this.vp.height = $viewport.offsetHeight;

            // Check if landscape or not
            Vacuum.isDesktop && this._updateVH();
            this.bLandscape = this._isLandscape();

            // Find VH values
            const vhmin = Math.min(height, this.vp.height, this.vheight);
            const vhMax = this.vp.height;
            const bDeviceBarVisible = height !== this.vp.height;

            if(bDeviceBarVisible)
            {
                _vhDelta = vhMax - vhmin;
                this.vhMin = vhmin;
            }
            else
            {
                this.vhMin = vhMax - _vhDelta;
            }

            this.vhMax = vhMax;

            if(Vacuum.isDevice && _bDeviceBarVisible !== bDeviceBarVisible)
            {
                _bDeviceBarVisible = bDeviceBarVisible;
                document.documentElement.classList[bDeviceBarVisible ? 'add' : 'remove']('has-device-bar');
            }

            // Apply to dom
            const DomStyle = document.documentElement.style;

            DomStyle.setProperty('--vpheight', `${(this.vp.height / config.fontSize).toFixed(3)}rem`);
            DomStyle.setProperty('--vhmin', `${(this.vhMin / (100 * config.fontSize)).toFixed(3)}rem`);
            DomStyle.setProperty('--vhmax', `${(this.vhMax / (100 * config.fontSize)).toFixed(3)}rem`);

            // Dispatch change
            this.emit('r');
        };

        window.addEventListener('resize', handler);
        handler();
    }

    /** @private */
    _isLandscape()
    {
        const bIsLandscape = this.width > this.height;

        if(bIsLandscape !== this.bLandscape)
        {
            this.bLandscape = bIsLandscape;

            if(bIsLandscape)
            {
                document.documentElement.classList.remove('is-portrait');
                document.documentElement.classList.add('is-landscape');
            }
            else
            {
                document.documentElement.classList.add('is-portrait');
                document.documentElement.classList.remove('is-landscape');
            }

            this._updateVH();
        }
        return bIsLandscape;
    }

    /** @private */
    _updateVH()
    {
        this.vheight = this.height;
    }
}

export default new ResizeManager();
