<template>
    <div id="app">
        <logo-page/>
        <logo/>
        <sticky-hor/>
        <!-- <stickyVertcorrection/> -->
        <!-- <sticky-vert-o/> -->
        <!-- <sticky-vert/> -->
        <Untitled/>
        <textHover/>
        <footer-axp-2/>


  </div>
</template>

<script>

import StickyHor from './components/stickyHor.vue'
import Logo from './components/logo.vue'
import textHover from './components/textHover.vue'
import Untitled from './components/Untitled.vue'
import LogoPage from './components/logoPage.vue'
import FooterAxp2 from './components/footerAxp2.vue'



export default {
  name: 'App',
    components: {
        Logo,
        StickyHor,
        Untitled,
        textHover,
        FooterAxp2,
        LogoPage,

    },
    data () {
        return {
            heightBodyMax:null,
        }
    },


   mounted: function() {
        const $app = document.querySelector('#app');
        let bound =  $app.getBoundingClientRect();

        const heightBodyMax = bound.height;
        console.log(heightBodyMax )

        // window.addEventListener("scroll", function (e){

        // window.scrollTo(0,0);
        // }, false);


   },


}


</script>

<style lang="scss">


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

img {
    width: 100%;
    height: auto;
}

.outer-container {
    border-left: 110px solid transparent;
    border-right: 110px solid transparent;
    @media (max-width: 1919px) {
                border-left: 80px solid transparent;
                border-right:80px solid transparent;
    }
      @media (max-width: 768px) {
                border-left: 25px solid transparent;
                border-right:25px solid transparent;
    }

}

h1 h2 h3 h4 h5 h6 p {
    font-family:Macellus ;
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 0;

}

logo{
    position:sticky;
}

#app{
    position: relative;

}





</style>
