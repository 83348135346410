<template>
<div class="box" id="footer" ref="footer">
    <div class='box-resize' >
        <div class='outer-container'>

            <div class="textImg">

                <div class="adresse">
                    <p class="t1">NOTRE SALON</p>
                    <p class="t2">Alexandre Pichoux</p>
                    <p class="t3">+336 29 66 37 37</p>
                    <p class="t4">127 avenue Philippe Auguste</p>
                    <p class="t5">75011 Paris</p>
                    <p class="t6">Instagram</p>
                    <p class="t7">@AxP</p>
                </div>
                <div class="imgF">
                    <img src="../assets/entre.png" alt="">
                </div>
            </div>
            <div class="rV">
                <p>Prendre <br> Rendez-vous</p>
                <div class="arrow">
                    <img src="../assets/svg/Arrow.svg" alt="">
                </div>
            </div>
        </div>


    </div>
</div>

</template>

<script>
    import App from '../App.vue';

    import windowScrollPosition from '../window-scroll-position.js';

export default {

    mixins: [ windowScrollPosition('position') ],

    mounted: function()
    {

        // Grab Dom elements
        const $boxFooters = this.$el.querySelector('.outer-container');
        const $boxRV = this.$el.querySelector('.rV');
        const $boxImgText = this.$el.querySelector('.textImg');

        // test pour recuperer heightbodyMax du composant parent app.vue
        // const $app = App.heightBodyMax;
        // console.log(App.heightBodyMax);


        // Create cached images array
        const $cachedFooters = [];

            // Create cache variable and set it to the array
            $cachedFooters .push(
                {
                    $el:$boxFooters,
                    position: { start: 0, end: 0 },
                }
            );


        const onResize = () =>
        {
            // Get the current scroll position for this viewport size
            const scrollPositionY = this.position[1];

            // Get viewport Height
            const viewportHeight = this.$rm.height;

                // Get bounding
            let bound =  $boxFooters.getBoundingClientRect();


                       // Calculate start position
            $cachedFooters[0].position.start = scrollPositionY + bound.top - viewportHeight;


            $cachedFooters[0].position.end = (scrollPositionY + bound.top - viewportHeight) + bound.height;






        }

        const render = () =>
        {
            // Get current scroll position
            const scrollPositionY = this.position[1];

            //  chiffre a changer avec heightbody Max du composant app
            // let progress = (scrollPositionY /10419 )


            // if (progress < 0.70 ) {
            //     $boxFooters.style.display = `none`;
            // } else {
            //      $boxFooters.style.display = `inline`;
            // }

            // let moveXRv = (1 - progress) * 100 -8
            //  moveXRv =  moveXRv < 0 ? 0 : ( moveXRv > 10 ? 10 :  moveXRv);
            //  console.log( moveXRv )
            if (window.matchMedia("(min-width: 769px)").matches) {

                 let progress = (scrollPositionY /12515 )


                if (progress < 0.70 ) {
                    $boxFooters.style.display = `none`;
                } else {
                    $boxFooters.style.display = `inline`;
                }

                let moveXRv = (1 - progress) * 100 -8
                moveXRv =  moveXRv < 0 ? 0 : ( moveXRv > 10 ? 10 :  moveXRv);

                $boxRV.style.transform = `translateX(${  moveXRv.toFixed(4) }rem)`;
                $boxImgText.style.transform = `translateY(${ moveXRv.toFixed(4) }rem)`;
            } else {

                let progress = (scrollPositionY /6452 )


                if (progress < 0.50 ) {
                    $boxFooters.style.display = `none`;
                } else {
                    $boxFooters.style.display = `flex`;
                }
                 let moveXRv = (1 - progress) * 100 -8
                moveXRv =  moveXRv < 0 ? 0 : ( moveXRv > 19 ? 19 :  moveXRv);

                $boxRV.style.transform = `translateY(${  moveXRv.toFixed(4) }rem)`;
                $boxImgText.style.transform = `translateY(${ moveXRv.toFixed(4) }rem)`;
            }

            // Request an animation frame to loop this function
            requestAnimationFrame(render);

        }

        // Listen resize
        this.$rm.on('r', onResize);
        // Call resize immediately to be sure to have the current sizes
        onResize();
        // Request an animation frame
        requestAnimationFrame(render);
    }
}
</script>


<style lang="scss" scoped>

.textImg{
    display: flex;
    flex-direction: row;
    justify-content: end;
    transform: translateY(0rem);
    @media (max-width: 768px) {


    }
    & .adresse{
        margin-right: 4rem;
        @media (max-width: 768px) {
            margin-right: 2rem;

        }
    }
      & .t1{

            font-Weight: 700;
            margin-bottom:2.77vh;
        }
        & .t3{


            margin-bottom: 1.85vh;
        }
        & .t5{


            margin-bottom: 1.85vh;
        }

    & p{
        margin: 0px;
        font-family: Marcellus;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: end;

    }
    .imgF{
        width:11.61vw ;
        height:32.40vh ;
        @media (max-width: 768px) {
            width:21.86vw ;
            height:auto ;
            margin-bottom: 2rem;

        }
        @media (max-width: 425px) {
            width:58.86vw ;
            height:auto ;
            margin-bottom: 2rem;

        }
    }
}

.rV{
    font-size:11vw ;
    font-family: Marcellus;
    white-space:nowrap;
    transform: translateX(0rem);
}



// Version 1440


.box {
    position: relative;
    z-index: -3;
    // border: solid 2px greenyellow;
    height: 100vh;
    &::before {
        content: '';
        display: block;
        padding-top: 57.39%;
        // background-color: purple;
    }
    @media (max-width: 768px) {
        height: 100vh;


    }
}



.outer-container {
    padding: 5.72vw 0;
    position:fixed;
    top: 0; left: 0; right: 0; bottom: 0;

    @media (max-width: 768px) {

        display:flex;
        padding: 23vw 0;
        flex-direction: column;


    }
     @media (max-width: 425px) {
        padding: 5.72vw 0;
        display:flex;

        flex-direction: column;


    }
}

.rV {
    position: absolute;
    top:41%;
    display:flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 768px) {
        position: relative;
        top:0;

    }
     @media (max-width: 425px) {
        position: relative;
        top:0rem;

    }

}

.arrow {
    display: flex;
    align-items: flex-end;
    padding-bottom: 2.4rem;
    width: 14vw;
     @media (max-width: 1024px) {
        padding-bottom: 1.7rem;

    }
}
</style>



