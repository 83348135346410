<template>
    <div id='box'>
        <div class='boxVerti js-box'>
            <div class='circle-img js-img'>
                <img src='../assets/fond1.png' alt>
            </div>
            <div class='circle-img js-img'>
                <img src='../assets/fond2.png' alt>
            </div>
        </div>
    </div>
</template>

<style lang='scss' scoped>

#box
{

    height: 300vh;
    // border: 2px solid black;
    background-color: white;

    .boxVerti
    {
        position: sticky;
        top: 0px; left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        // border: blue 2px solid;
    }

    .circle-img
    {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        clip-path: circle(0% at 50% 50%);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1.3);
        }
    }
}
</style>


<script>

import config from './../config';
import windowScrollPosition from '../window-scroll-position.js';

export default {

    mixins: [ windowScrollPosition('position') ],

    mounted: function()
    {

        // Grab Dom elements
        const $imgs = this.$el.querySelectorAll('.js-img');
        const length = $imgs.length;

        // Create cached images array
        const $cachedImg = [];

        // Loop the images
        for (let _index = 0; _index < length; _index++)
        {
            // Grab each Image
            const $img = $imgs[_index];

            // Create cache variable and set it to the array
            $cachedImg.push(
                {
                    $el: $img,
                    $img: $img.firstElementChild,
                    position: { start: 0, end: 0 },
                }
            );
        }

        const onResize = () =>
        {
            // Get the current scroll position for this viewport size
            const scrollPositionY = this.position[1];

            // Get viewport Height
            const viewportHeight = this.$rm.height;

            // Loop all cached images to update start and end values
            for (let _index = 0; _index < $cachedImg.length; _index++)
            {
                // Get cached img
                const cachedImg = $cachedImg[_index];

                // Get bounding
                const bound = cachedImg.$el.getBoundingClientRect();



                    const startPostionY = ((_index +0.7) * this.$rm.height) ;

                // Calculate start position
                cachedImg.position.start =  startPostionY + scrollPositionY + bound.top - viewportHeight;
                cachedImg.position.end = cachedImg.position.start + bound.height;




            }
        }


        const render = () =>
        {
            // Get current scroll position
            const scrollPositionY = this.position[1];

            for (let _index = 0; _index < length; _index++)
            {

                // Get cached img
                const cachedImg = $cachedImg[_index];

                let progress = ( scrollPositionY - cachedImg.position.start) / (cachedImg.position.end - cachedImg.position.start);
                progress = progress < 0 ? 0 : (progress > 1 ? 1 : progress);

                // Calculate clip path progress
                const clipPathProgress = (progress)* 100;

                // On veut que l'image soit plus grande au début et qu'elle
                // fasse sa taille initiale à la fin.
                // Pour cela on dit que de base le scale = 1;
                // const scale = 1;
                // Suite à ça on se dit qu'il faut qu'il fasse au max 1.2
                // Donc il faut que le progress permette d'aller de 1.2 à 1 donc
                // qu'il fasse :
                // const scale = (1.2 - 1) * progress + 1;
                // Comment on veut que ce soit au début que l'image soit grosse
                // On inverse le progress
                // const scale = .2 * (1 - progress ) + 1;
                const scale = (1 - progress) * .2 + 1;

                // Les .toFixed(value) permette de convertir
                // un nombre en string en délimitant le nombre d'élément
                // après la virgule
                // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed
                cachedImg.$el.style.clipPath = `circle(${ clipPathProgress.toFixed(4) }% at 50% 50%)`;
                cachedImg.$img.style.transform = `scale(${ scale.toFixed(4) })`;

            }

            // Request an animation frame to loop this function
            requestAnimationFrame(render);

        }

        // Listen resize
        this.$rm.on('r', onResize);
        // Call resize immediately to be sure to have the current sizes
        onResize();
        // Request an animation frame
        requestAnimationFrame(render);
    }
}
</script>
