<template>
    <div class="box">
        <div class='outer-container'>
            <div class="text ">
                <p class="t1 tx">Couleur</p>
                <div class="imgBox">
                    <img class="imgT imgT4" src="../assets/couleur.png" alt="">
                </div>
            </div>

            <div class="text ">
                <p class="t2 tx">Balayage</p>
                <div class="imgBox">
                    <img class="imgT imgT4" src="../assets/blackWhite.jpg" alt="">
                </div>
            </div>
            <div class="text ">
                <p class="t3 tx">Brushing</p>
                <div class="imgBox">
                    <img class="imgT imgT4" src="../assets/cheveu.jpg" alt="">
                </div>
            </div>
            <div class="text">
                <p class="t4 tx">Soin du cheveu</p>
                <div class="imgBox">
                    <img class="imgT imgT4" src="../assets/tresse.jpg" alt="">
                </div>

            </div>
            <div class="text ">
                <p class="t5 tx">Lissage</p>
                <div class="imgBox">
                    <img class="imgT imgT4" src="../assets/fond1resize.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import windowScrollPosition from '../window-scroll-position.js';

export default {
    mixins: [ windowScrollPosition('position') ],
    mounted: function() {

        // recuperation du texte dans le dom
       const $cachedText = [];

        const $textHover = this.$el.querySelectorAll('.text');
        const $box = this.$el.querySelectorAll('.box');

            // Loop the images
        for (let _index = 0; _index < $textHover.length; _index++)
        {
            // Grab each Image
            const cachedText = $textHover[_index];

            // Create cache variable and set it to the array
            $cachedText.push(
                {
                    $el: cachedText,
                    position: { start: 0, end: 0 },
                }
            );
        }


         const onResize = () =>
        {
                        // Get the current scroll position for this viewport size
            const scrollPositionY = this.position[1];

            // Get viewport Height
            const viewportHeight = this.$rm.height;

            // Loop all cached images to update start and end values
            for (let _index = 0; _index < $textHover.length; _index++)
            {


                // Get cached img
                const cachedText = $cachedText[_index];

                // Get bounding
                const bound = cachedText.$el.getBoundingClientRect();

                //


                // Calculate start position
                cachedText.position.start =   scrollPositionY + bound.top - viewportHeight;
                // Calculate end position
                // cachedImg.position.end = scrollPositionY + bound.bottom  - viewportHeight;
                cachedText.position.end = cachedText.position.start + bound.height;

            }
        };


        const render = () =>
        {
            // Get current scroll position
            const scrollPositionY = this.position[1];

            for (let _index = 0; _index < $textHover.length; _index++)
            {

                // Get cached img
                const cachedText = $cachedText[_index];

                let progress = ( scrollPositionY - cachedText.position.start) / (cachedText.position.end - cachedText.position.start);
                progress = progress < 0 ? 0 : (progress > 1 ? 1 : progress);


                const translateY = ((1 - progress) -0.5) *(5.5)  ;

// console.log(translateY)
            if (window.matchMedia("(min-width: 751px)").matches) {
                 cachedText.$el.style.transform = `translateY(${ translateY.toFixed(4) }rem)`;
            }
                // cachedText.$el.style.transform = `translateY(${ translateY.toFixed(4) }rem)`;

            }

            // Request an animation frame to loop this function
            requestAnimationFrame(render);

        }

        // Listen resize
        this.$rm.on('r', onResize);
        // Call resize immediately to be sure to have the current sizes
        onResize();
        // Request an animation frame
        requestAnimationFrame(render);

    }
}

</script>


<style lang="scss" scoped>



.box
{
    // width:100vw;
    background: white;
    // border: rgb(119, 0, 255) 2px solid;

}

.outer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, rgba(218, 182, 124, 0) 0%, rgba(218, 182, 124, 0.9) 100%);

       @media (max-width: 768px) {
        height: 100vh;
        justify-content: center;

    }
}

.text {
    font-size:10.4166666667vw ;
    cursor: pointer;
    padding: 2vw 0;
    position: relative;
    top: 0rem;
    font-family: 'Marcellus';
    font-weight: 400;
    transform: translateY(0rem);
    white-space:nowrap;
    margin-top: 1vw;
      @media (max-width: 768px) {
          cursor:context-menu;
          padding: 4vw 0;
        }
    & p{
        transition: all 1s ease-out;
    }
    &:nth-child(2) { align-self: flex-end; transform: translateX(-20%); }
    &:nth-child(3) { transform: translateX(25%); }
    &:nth-child(4) {transform: translateX(3%); }
    &:nth-child(5) {transform: translateX(20%); }
    & .imgBox {
        width: 13.02vw;
        height: 41.66vh;
        overflow: hidden;
        position: absolute;
        top: -5%;
        transform: translateY(9.50vh) translateX(-15.62vw) scale(0);
        transition: 1200ms ease-out;

        & .imgT{
            width: 100%;
            height: auto;
            clip-path: circle(0% at 50% 50%);
            transition: 1300ms ease-in-out;
        }
    }
    & .tx:hover {
        color:rgb(112, 112, 112) ;
        transform: translateX(50px);
        @media (max-width: 768px) {
           transform: translateX(0);
           color:rgb(0, 0, 0) ;
        }
    }
        & .tx:hover ~ .imgBox {
        transform: translateY(-3vh) translateX(0) scale(1);
        transition: 1200ms ;
        @media (max-width: 768px) {
           transform: translateY(-0) translateX(0) scale(1);
        }
               & .imgT {
                    clip-path: circle(100% at 50% 50%);
                    @media (max-width: 768px) {
                       clip-path: circle(00% at 50% 50%);
                    }
                }
    }


}


</style>
